// import "core-js/stable";
// import "regenerator-runtime/runtime";
import React from 'react';
import Trans from 'next-translate/Trans';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import styles from './PageError.module.scss';
import Asset from '../Asset';
import ElementLink from '../ElementLink';

function PageError({lang}) {
  return (
    <DynamicNamespaces
      dynamic={(_, ns) => {
        return import(`../../../locales/${lang}/common.json`)
           .then((m) => m.default)
           .catch(() => ({}));
       }}
      namespaces={['common']}
      fallback="Loading...">
      <div className={styles.page} data-testid="PageError">
        <div>
          <Asset url="/images/assets/spilled-water-illustration.png" bypassOptimization={true} />

          <h1 data-testid="PageError-headline">
            <Trans i18nKey="common:textError404" />
          </h1>

          <h4 data-testid="PageError-summary">
            <Trans i18nKey="common:textErrorPagePageNotFound" />
          </h4>

          <div className={styles.helpfulLinksOuterWrap}>
            <div className={styles.helpfulLinksHeaderTextWrap}>
              <p className={styles.helpfulLinksHeaderText} data-testid="PageError-linkHelpText">
                <Trans i18nKey="common:textErrorPageHelpfulLinks" />
              </p>
            </div>

            <div className={styles.helpfulLinksWrap}>
              <ul className={styles.helpfulLinks}>
                <li>
                  <ElementLink href="/" linkText="Homepage" className={styles.logo} isModal={false} download={false}>
                    <Trans i18nKey="common:textErrorPageHome" />
                  </ElementLink>
                </li>

                <li>
                  <ElementLink
                    href={`https://www.dropbox.com/login?cont=${process.env.HOST}`}
                    linkText="Sign-In"
                    className={styles.logo}
                    isModal={false}
                    download={false}>
                    <Trans i18nKey="common:textErrorPageSignIn" />
                  </ElementLink>
                </li>

                <li>
                  <ElementLink
                    href="https://help.dropbox.com/"
                    linkText="Help Center"
                    className={styles.logo}
                    isModal={false}
                    download={false}>
                    <Trans i18nKey="common:textErrorPageHelpCenter" />
                  </ElementLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DynamicNamespaces>
  );
}

export default PageError;
